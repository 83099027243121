<template>
  <div>
    <statistics />
    <b-overlay :show="loader" rounded="sm">
      <b-row>
        <b-col class="col-md-2">
          <BFormGroup label="Поиск">
            <BFormInput
              v-model="filter.search"
              type="search"
              @input="searchConstructor"
            />
          </BFormGroup>
        </b-col>

        <b-col class="col-md-2">
          <BFormGroup label="Оператор">
            <v-select
              v-model="filter.DK_OPERATOR"
              class="bg-white rounded"
              :options="users.results"
              label="full_name"
              :reduce="(p) => p.id"
              @input="filterData"
            />
          </BFormGroup>
        </b-col>

        <BCol md="2">
          <BFormGroup label="Взыскатель">
            <VSelect
              v-model="filter.CLAIMER_CRM_COMPANY_ID"
              class="bg-white rounded"
              label="COMPANY_NAME"
              :reduce="(p) => p.COMPANY_ID"
              :options="debtCompanyList.results"
              @input="filterData"
            />
          </BFormGroup>
        </BCol>

        <BCol md="2">
          <BFormGroup label="Статус">
            <VSelect
              v-model="last_action_name"
              class="bg-white rounded"
              label="title"
              multiple
              :options="LAST_ACTION_NAME"
              @input="filterLastAct"
            />
          </BFormGroup>
        </BCol>

        <b-col class="col-md-3 text-right ml-auto">
          <b-button
            class="mr-1"
            style="margin-top: 27px"
            variant="primary"
            :disabled="exportXls"
            @click="exportExcel"
          >
            <b-spinner
              v-if="exportXls"
              small
              class="mr-1"
              label="Small Spinner"
            />
            <feather-icon v-else icon="DownloadIcon" />
            Export.xls
          </b-button>

          <b-button
            style="margin-top: 27px"
            variant="primary"
            @click="detailCollector"
          >
            <feather-icon icon="PlayIcon" />
            Старт
          </b-button>
        </b-col>
      </b-row>

      <b-card class="mt-1">
        <b-table
          :fields="fields"
          :items="dkList.results"
          show-empty
          hover
          responsive
          @row-clicked="onRowSelected"
        >
          <template #empty>
            <empty />
          </template>
          <template #cell(id)="props">
            <div class="d-flex align-items-center" @click.stop>
              <BFormCheckbox
                v-if="!getDkOperator.permissions.includes('dk_operator')"
                class="mr-1"
                :checked="checkId.debts.includes(props.item.id)"
                @change="onPreviewClick($event, props.item.id, props.index)"
              />

              <span> {{ props.item.id }}</span>
            </div>
          </template>
          <template #cell(DEBT)="props">
            <div v-if="props.item.BASIS">
              {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
            </div>
          </template>
          <template #cell(DO_STAGE)="props">
            <div v-if="props.item.BASIS">
              {{ DO_STAGE_TEXT(props.item.BASIS.DO_STAGE) }}
            </div>
          </template>
          <template #cell(IS_ACTIVE)="props">
            <b-avatar v-if="!props.value" variant="light-danger">
              <feather-icon icon="XCircleIcon" size="18" />
            </b-avatar>
          </template>
          <template #cell(IS_CONTACT_ACTIVE)="props">
            <b-avatar :variant="props.value ? 'light-success' : 'light-danger'">
              <feather-icon
                :icon="props.value ? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </b-avatar>
          </template>
          <template #cell(DEBT_STATUS)="props">
            <b-badge
              v-if="props.item.LAST_ACTION_NAME"
              :variant="
                props.item.LAST_ACTION_NAME.match(
                  /danger|dark|success|warning|secondar|info|primary|light-danger|warning-closed|warning-expired|success/
                )
                  ? props.item.LAST_ACTION_NAME
                  : 'secondary'
              "
            >
              {{ getTextDebtStatus(props.item.LAST_ACTION_NAME) }}
            </b-badge>
          </template>
        </b-table>
        <BRow
          v-if="!getDkOperator.permissions.includes('dk_operator')"
          class="mx-1 mb-2"
        >
          <BCol md="3" class="d-flex align-items-center">
            <BFormCheckbox v-model="allChecked" @input="checkAll">
              Для всех
            </BFormCheckbox>
          </BCol>
          <BCol md="3" class="d-flex align-items-end">
            <BFormGroup label="Назначить ответственного" class="w-100">
              <VSelect
                v-model="checkId.dk_operator"
                label="full_name"
                :reduce="(p) => p.id"
                :options="users.results"
              />
            </BFormGroup>
          </BCol>
          <BCol md="3" class="d-flex align-items-end mb-1">
            <BButton variant="primary" @click="sendCheckedId">
              Применить
            </BButton>
          </BCol>
        </BRow>
      </b-card>

      <b-card>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap mr-1">Показать по</span>
            <b-form-select
              v-model="filter.page_size"
              :options="['5', '10', '20']"
              class="mx-1"
              @change="refresh"
            />
            <span class="ml-1 text-nowrap"> строк( {{ dkList.count }} )</span>
          </div>
          <div>
            <b-pagination
              v-model="filter.page"
              :total-rows="dkList.count"
              :per-page="filter.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BCard,
  BFormSelect,
  BPagination,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BOverlay,
  BBadge,
  BSpinner,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import empty from "@/components/empty.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VSelect from "vue-select";
import statistics from "./statistics.vue";

export default {
  components: {
    BFormCheckbox,
    BSpinner,
    statistics,
    empty,
    VSelect,
    BBadge,
    BButton,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,
    BAvatar,
  },
  data() {
    return {
      exportXls: false,
      allChecked: false,
      checkId: {
        debts: [],
        dk_operator: null,
      },
      loader: false,
      playBtn: false,
      last_action_name: [],
      filter: {
        search: null,
        DK_OPERATOR: null,
        CLAIMER_CRM_COMPANY_ID: null,
        page: 1,
        page_size: 20,
      },
      fields: [
        {
          label: "№",
          key: "id",
        },
        {
          label: "ФИО должника",
          key: "BORROWER",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
          editable: true,
        },
        {
          label: "Наименование портфеля",
          key: "CLAIMER_CRM_COMPANY_ID",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
          editable: true,
        },
        {
          label: "Сумма долга",
          key: "DEBT",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
          editable: true,
        },
        {
          label: "Оператор",
          key: "DK_OPERATOR.full_name",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
        },
        {
          label: "Исключен",
          key: "IS_ACTIVE",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
        },
        {
          label: "Активный",
          key: "IS_CONTACT_ACTIVE",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
        },
        {
          label: "СТАТУС",
          key: "DEBT_STATUS",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
        },
        {
          label: "Дата последнего действия",
          key: "LAST_ACTION_DATE",
          thClass: "tableHeadClass",
          tdClass: "tableHeadClass",
          editable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState("dk", ["dkList", "currentId", "users", "LAST_ACTION_NAME"]),
    ...mapState("cases", ["debtCompanyList"]),

    getDkOperator() {
      return JSON.parse(localStorage.getItem("userData"));
    },
  },
  mounted() {
    const req = {
      page_size: 1000,
    };
    this.refresh();
    this.FETCH_DK_STATISTICS({ role_unique_name: "dk" });
    this.FETCH_USERS({ role_unique_name: "dk", ...req });
    this.FETCH_SK_DEBT_COMPANY_LIST(req);
  },
  methods: {
    ...mapActions("dk", [
      "FETCH_DK_LIST",
      "FETCH_CURRENT_ID",
      "FETCH_DK_STATISTICS",
      "FETCH_USERS",
      "UPDATE_DK_OPERATOR",
      "FETCH_DK_EXCEL",
    ]),
    ...mapMutations("collectorDetail", ["SET_ACTIVE", "SET_CALL"]),
    ...mapActions("cases", ["FETCH_SK_DEBT_COMPANY_LIST"]),

    filterData() {
      this.filter.page = 1;
      this.refresh();
    },

    filterLastAct() {
      this.filter.page = 1;
      const LAST_ACTION_NAME = this.last_action_name
        .map((e) => e.value)
        .join(",");

      this.FETCH_DK_STATISTICS({
        role_unique_name: "dk",
        ...this.filter,
        LAST_ACTION_NAME,
      });
      this.FETCH_DK_LIST({
        ...this.filter,
        LAST_ACTION_NAME,
      });
    },

    searchConstructor() {
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.filter.page = 1;
        this.refresh();
      }, 500);
    },

    exportExcel() {
      const LAST_ACTION_NAME = this.last_action_name
        .map((e) => e.value)
        .join(",");
      this.exportXls = true;
      this.FETCH_DK_EXCEL({ LAST_ACTION_NAME, ...this.filter })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$_okToast();
          this.exportXls = false;
        })
        .catch((error) => {
          this.$_errorToast(error);
          this.exportXls = false;
        });
    },

    onPreviewClick(val, id) {
      let replaceIndex;
      if (val) {
        for (const key of this.checkId.debts) {
          if (id > key) {
            replaceIndex = this.checkId.debts.indexOf(key) + 1;
          }
        }
        this.checkId.debts.splice(replaceIndex, 0, id);
      } else {
        const deleteItemIndex = this.checkId.debts.indexOf(id);
        this.checkId.debts.splice(deleteItemIndex, 1);
      }
    },

    async sendCheckedId() {
      if (this.checkId.debts[0]) {
        this.filter.page = 1;
        await this.UPDATE_DK_OPERATOR(this.checkId);
        await this.refresh();
        this.checkId = {
          debts: [],
          dk_operator: null,
        };
        this.allChecked = false;
        await this.$_okToast();
      }
    },

    checkAll(e) {
      if (e) {
        this.dkList.results.map((i) => {
          i.check = this.allChecked;
          this.checkId.debts.push(i.id);
        });
      } else {
        this.allChecked = false;
        this.checkId.debts = [];
      }
    },

    async refresh() {
      try {
        const LAST_ACTION_NAME = this.last_action_name
          .map((e) => e.value)
          .join(",");
        this.loader = true;
        this.FETCH_DK_STATISTICS({
          role_unique_name: "dk",
          LAST_ACTION_NAME,
          ...this.filter,
        });
        await this.FETCH_DK_LIST({ LAST_ACTION_NAME, ...this.filter });
      } catch (e) {
        this.loader = false;
        await this.$_errorToast(e);
      }
      this.loader = false;
    },

    getTextDebtStatus(value) {
      const message = {
        secondary: "Неправильный номер",
        "warning-closed": "Обещание выполнено",
        "warning-expired": "Обещание истекло",
        primary: "Связь прервалась",
        "light-danger": "Отказ",
        dark: "Банкрот",
        danger: "Неперспективный",
        info: "Не ответил",
        warning: "Обещание",
        success: "Оплачено",
      };

      return message[value] || value;
    },

    async onRowSelected(params) {
      try {
        this.loader = true;
        await this.FETCH_CURRENT_ID({ current_id: params.id }).then(() => {
          this.SET_ACTIVE(1);
          this.SET_CALL(false);

          this.$router.push({
            name: "collector-id",
            query: { play: "true" },
            params: {
              id: params.id,
            },
          });
          this.loader = false;
        });
      } catch (e) {
        this.loader = false;
        await this.$_errorToast(e);
      }
    },

    async detailCollector() {
      try {
        this.loader = true;
        await this.FETCH_CURRENT_ID().then(() => {
          this.SET_ACTIVE(1);
          this.SET_CALL(false);

          this.$router.push({
            name: "collector-id",
            query: { play: "true" },
            params: {
              id: this.currentId.current_id,
            },
          });
          this.loader = false;
        });
      } catch (e) {
        this.loader = false;
        if (e && e.message === "Request failed with status code 404") {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Нет активных ",
                icon: "AlertCircleIcon",
                variant: "warning",
              },
            },
            {
              position: "bottom-right",
            }
          );
        } else {
          await this.$_errorToast(e);
        }
      }
    },

    changePage(page) {
      this.filter.page = page;
      this.refresh();
    },

    DO_STAGE_TEXT(value) {
      const message = {
        voluntary_payment: "Добровольная оплата",
        claim: "Претензия",
        judical_work: "Судебная работа",
        executive_document: "Исполнительный документ",
        executive_work: "Исполнительное производство",
        stopped: "Приостановлено",
        paid: "Погашен",
      };

      return message[value] || "";
    },
  },
};
</script>
<style>
.badge-warning-expired {
  background-color: red !important;
}

.badge-warning-closed {
  background-color: green !important;
}
</style>
